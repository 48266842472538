<template>
  <b-card class="p-2" v-if="datasource.loaded">
    <div class="mb-4">
      <div class="d-flex">
        <div>
          <b-avatar :src="user_profile" size="64px" ref="previewEl" />
        </div>
        <div class="f-flex flex-column ml-2">
          <h4>
            {{
              datasource.user.namesurname.length == 0
                ? "Yeni Kullanıcı"
                : datasource.user.namesurname
            }}
          </h4>
          <div>{{ datasource.user.email_primary }}</div>
          <div>{{ datasource.user.gsm_primary }}</div>
        </div>
      </div>
    </div>
    <b-tabs pills>
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Hesap Bilgileri</span>
        </template>
        <div class="mt-2 pt-75">
          <b-media class="mb-2">
            <div class="d-flex flex-wrap" v-if="false">
              <b-button variant="primary" @click="$refs.refInputEl.click()">
                <input
                  ref="refInputEl"
                  type="file"
                  class="d-none"
                  @input="inputImageRenderer"
                />
                <span class="d-none d-sm-inline">Update</span>
                <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
              </b-button>
              <b-button variant="outline-secondary" class="ml-1">
                <span class="d-none d-sm-inline">Remove</span>
                <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
              </b-button>
            </div>
            <b-form>
              <b-row>
                <b-col cols="12" md="6">
                  <div class="form-group">
                    <label class="font-medium text-uppercase">Ad Soyad</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="datasource.user.namesurname"
                    />
                  </div>
                </b-col>
                <b-col cols="12" md="6">
                  <div class="form-group">
                    <label class="font-medium text-uppercase"
                      >Öncelikli GSM</label
                    >
                    <input
                      v-model="datasource.user.gsm_primary"
                      type="number"
                      class="form-control"
                    />
                  </div>
                </b-col>
                <b-col cols="12" md="6">
                  <div class="form-group">
                    <label class="font-medium text-uppercase">Email/İş</label>
                    <input
                      v-model="datasource.user.email_primary"
                      type="email"
                      class="form-control"
                    />
                  </div>
                </b-col>
                <b-col cols="12" md="6">
                  <div class="form-group">
                    <label class="font-medium text-uppercase">Parola</label>
                    <input
                      v-model="datasource.user.password"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </b-col>
                <b-col cols="12" md="6">
                  <div class="form-group">
                    <label class="font-medium text-uppercase">Durum</label>
                    <v-select
                      :searchable="false"
                      :clearable="false"
                      v-model="datasource.user.is_active"
                      :options="[
                        { label: 'Aktif', value: 1 },
                        { label: 'Pasif', value: 0 },
                      ]"
                    />
                  </div>
                </b-col>
                <b-col cols="12" md="6">
                  <div class="form-group">
                    <label class="font-medium text-uppercase"
                      >Görev Aldığı Şube</label
                    >
                    <v-select
                      v-model="datasource.user.office_id"
                      :options="datasource.offices"
                      :searchable="false"
                      :clearable="false"
                      @input="changeOffice()"
                    />
                  </div>
                </b-col>
                <b-col cols="12" md="6">
                  <div class="form-group">
                    <label class="font-medium text-uppercase"
                      >Görev Aldığı Departman</label
                    >
                    <v-select
                      v-model="datasource.user.department_id"
                      :options="
                        datasource.departments.length == 0
                          ? [{ label: 'Seçin', value: '' }]
                          : datasource.departments
                      "
                      :searchable="false"
                      :clearable="false"
                    />
                  </div>
                </b-col>
                <b-col cols="12" md="6">
                  <div class="form-group">
                    <label class="font-medium text-uppercase">Yöneticisi</label>
                    <v-select
                      v-model="datasource.user.parent_user_id"
                      :options="
                        datasource.users.length == 0
                          ? [{ label: 'Seçin', value: '' }]
                          : datasource.users
                      "
                      :clearable="false"
                    />
                  </div>
                </b-col>
                <b-col cols="12" md="6">
                  <div class="form-group">
                    <label class="font-medium text-uppercase">Ünvan</label>
                    <v-select
                      v-model="datasource.user.title_name"
                      :options="datasource.titles"
                      :clearable="false"
                      :reduce="(option) => option.value"
                    />
                  </div>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <div class="form-group">
                    <label class="font-medium text-uppercase">İşe Başlangıç Tarihi</label>
                    <flat-pickr
                      v-model="datasource.user.job_start_date"
                      class="form-control"
                      :config="{ dateFormat: 'd.m.Y', locale: 'tr' }"
                    />
                  </div>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <div class="form-group">
                    <label class="font-medium text-uppercase"
                      >İşten Ayrılma Tarihi</label
                    >
                    <flat-pickr
                      v-model="datasource.user.job_end_date"
                      class="form-control"
                      :config="{ dateFormat: 'd.m.Y', locale: 'tr' }"
                    />
                  </div>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <div class="form-group">
                    <label class="font-medium text-uppercase"
                      >Erişim Seviyesi</label
                    >
                    <v-select
                      v-model="datasource.user.access_level"
                      :searchable="false"
                      :clearable="false"
                      :options="global.access_level"
                      :reduce="(curr) => curr.value"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </b-media>
        </div>
        <b-card no-body class="border mt-1">
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon icon="LockIcon" size="18" />
              <span class="align-middle ml-50">Yetkiler</span>
            </b-card-title>
          </b-card-header>
          <table class="table">
            <thead>
              <tr>
                <th style="width: 32px">#</th>
                <th class="text-left">Rol</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in datasource.roles" :key="index">
                <td style="width: 32px">
                  <b-form-checkbox :checked="item.is_checked" />
                </td>
                <td
                  v-on:click="item.is_checked = !item.is_checked"
                  class="text-left cursor-pointer"
                >
                  {{ item.role_category_name }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Kişisel Bilgiler</span>
        </template>
        <div class="mt-2 pt-75">
          <b-row>
            <b-col cols="12" md="6" lg="4">
              <div class="form-group">
                <label class="font-medium text-uppercase">Doğum Tarihi</label>
                <flat-pickr
                  v-model="datasource.user.dateofbirth"
                  class="form-control"
                  :config="{ dateFormat: 'd.m.Y', locale: 'tr' }"
                />
              </div>
            </b-col>
            <b-col cols="12" md="6" lg="4">
              <div class="form-group">
                <label class="font-medium text-uppercase">İkinci Telefon</label>
                <b-form-input v-model="datasource.user.gsm_secondary" />
              </div>
            </b-col>
            <b-col cols="12" md="6" lg="4">
              <div class="form-group">
                <label class="font-medium text-uppercase">Cinsiyet</label>
                <b-form-radio-group
                  v-model="datasource.user.gender"
                  name="gender"
                  value="1"
                  :options="[
                    { text: 'Kadın', value: 0 },
                    { text: 'Erkek', value: 1 },
                  ]"
                />
              </div>
            </b-col>
            <b-col cols="12" md="8" lg="8">
              <div class="form-group">
                <label class="font-medium text-uppercase">Açık Adres</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="datasource.user.address"
                />
              </div>
            </b-col>
          </b-row>
        </div>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ActivityIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Özet</span>
        </template>
        <div class="mt-2 mb-4 pt-75">
          <table class="table">
            <tbody>
              <tr v-if="datasource.user_stack.user.created_props != null">
                <td>Oluşturulma</td>
                <td>
                  {{
                    this.moment(
                      datasource.user_stack.user.created_props.created_at
                    ).format("DD.MM.YYYY HH:mm")
                  }}
                  -
                  {{ datasource.user_stack.user.created_props.created_by_name }}
                </td>
              </tr>
              <tr v-if="datasource.user_stack.user.updated_props != null">
                <td>Güncellenme</td>
                <td>
                  {{
                    this.moment(
                      datasource.user_stack.user.updated_props.updated_at
                    ).format("DD.MM.YYYY HH:mm")
                  }}
                  -
                  {{ datasource.user_stack.user.updated_props.updated_by_name }}
                </td>
              </tr>
              <tr v-if="datasource.user_stack.user.last_login != null">
                <td>Son Giriş</td>
                <td>
                  {{
                    this.moment(
                      datasource.user_stack.user.last_login.login_at
                    ).format("DD.MM.YYYY HH:mm")
                  }}
                </td>
              </tr>
              <tr v-if="datasource.user_stack.user.last_action != null">
                <td>Son İşlem</td>
                <td>
                  {{
                    this.moment(
                      datasource.user_stack.user.last_action.action_at
                    ).format("DD.MM.YYYY HH:mm")
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <button v-on:click="userlogout()" class="btn text-danger">
            Oturumu Sonlandır
          </button>
        </div>
      </b-tab>
    </b-tabs>

    <div class="d-flex justify-content-between">
      <button v-on:click="save()" class="btn btn-primary">Kaydet</button>
      <button v-on:click="confirmDelete()" class="btn btn-danger">Sil</button>
    </div>
  </b-card>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import vSelect from "vue-select";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import { tr } from "flatpickr/dist/l10n/tr.js";
import api from "@/service/api";
import utils from "@/service/utils";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    vSelect,
    flatPickr,
  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);
    const userAvatar = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {}
    );

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
  data() {
    return {
      datasource: {
        loaded: false,
        user: {
          namesurname: "",
          password: "",
          is_active: { label: "Pasif", value: 0 },
          office_id: { value: "", label: "Seçin" },
          department_id: { value: "", label: "Seçin" },
          parent_user_id: { value: "", label: "Seçin" },
          title_id: { value: "", label: "Seçin" },
          dateofbirth: "",
          gender: 1,
          gsm_primary: "",
          gsm_secondary: "",
          email_primary: "",
          email_secondary: "",
          job_start_date: "",
          job_end_date: "",
          address: "",
          roles: [],
        },
        offices: [],
        stack_offices: [],
        users: [],
        stack_users: [],
        departments: [],
        stack_departments: [],
        titles: [],
        stack_titles: [],
        roles: [],
        stack_roles: [],
      },
      user_profile:
        "https://cdn4.iconfinder.com/data/icons/glyphs/24/icons_user2-256.png",
      user_stack: null,
    };
  },
  created() {
    if (!utils.userHasRole("view_user")) {
      this.$router.push("/not-found");
      return;
    }
    utils.setTitle("Kullanıcı Bilgileri");
    this.getUser();
  },
  computed: {
    ...mapState({
      global: (state) => state.global,
    }),
  },
  methods: {
    getUser() {
      let param_user_uniq = this.$route.query.user;
      if (param_user_uniq == null) {
        //  this.$router.push("/error-404");
        return;
      }

      let data = { user_uniq: param_user_uniq };
      api.action("getUser", data).then((q) => {
        if (!q.success) {
          this.$router.push("/error-404");
          return;
        }

        this.getUserCreateData();
        this.datasource.user_stack = q.data;
        this.datasource.loaded = true;
      });
    },
    getUserCreateData() {
      api.action("userCreateData").then((q) => {
        if (q.success == false) {
          this.toast("warning", q.message);
          return;
        }
        this.datasource.stack_departments = q.data.departments;
        this.datasource.stack_users = q.data.users;
        this.datasource.stack_offices = q.data.offices;
        this.datasource.stack_titles = q.data.titles;
        this.datasource.stack_roles = q.data.user_roles;

        this.datasource.offices.push({
          value: "",
          label: "Seçin",
        });
        q.data.offices.forEach((qq) => {
          qq.value = qq.office_id;
          qq.label = qq.title;
          this.datasource.offices.push(qq);
        });

        q.data.titles.forEach((qq) => {
          qq.value = qq.definition_name;
          qq.label = qq.name;
          this.datasource.titles.push(qq);
        });

        q.data.user_roles.forEach((qq) => {
          let item = {
            role: qq.role,
            role_category: qq.role_category,
            role_category_name: qq.role_category_name,
            is_checked: false,
          };
          this.datasource.roles.push(item);
        });
        this.setUser();
      });
    },
    setUser() {
      let u = this.datasource.user_stack.user;
      this.datasource.user.namesurname = u.user_namesurname;
      this.datasource.user.is_active = {
        label: u.is_active ? "Aktif" : "Pasif",
        value: u.is_active,
      };
      this.datasource.user.office_id = this.datasource.offices.find(
        (q) => q.office_id == u.office_id
      );

      let office_departments = this.datasource.stack_departments.filter(
        (q) => q.office_id == u.office_id
      );

      office_departments.forEach((q) => {
        q.value = q.definition_id;
        q.label = q.name;
        this.datasource.departments.push(q);
      });

      this.datasource.user.department_id = this.datasource.departments.find(
        (q) => q.definition_id == u.department_id
      );

      let office_users = this.datasource.stack_users.filter(
        (q) => q.office_id == u.office_id
      );
      office_users.forEach((q) => {
        q.value = q.user_id;
        q.label = q.user_namesurname;
        this.datasource.users.push(q);
      });

      this.datasource.user.parent_user_id = this.datasource.users.find(
        (q) => q.user_id == u.parent_user_id
      );

      this.datasource.user.title_id = this.datasource.titles.find(
        (q) => q.definition_id == u.title_id
      );

      this.datasource.user.dateofbirth = u.dateofbirth;
      this.datasource.user.gender = u.gender;

      this.datasource.user.gsm_primary = u.user_gsm_primary;
      this.datasource.user.gsm_secondary = u.user_gsm_secondary;
      this.datasource.user.email_primary = u.user_email_primary;
      this.datasource.user.email_secondary = u.user_email_secondary;
      this.datasource.user.job_start_date = u.job_start_date;
      this.datasource.user.job_end_date = u.job_end_date;
      this.datasource.user.address = u.address;
      this.datasource.user.roles = u.roles;
      this.datasource.user.access_level = u.access_level;

      if (u.roles != null) {
        this.datasource.roles.forEach((q) => {
          q.is_checked =
            u.roles.find((r) => r == q.role) != null ? true : false;
        });
      }

      let breadcrumb = this.$route.meta.breadcrumb;

      breadcrumb[1].text = u.user_namesurname;
      this.$route.meta.breadcrumb = breadcrumb;
    },
    moment: function () {
      return moment();
    },
    userHasRole(rolename) {
      return utils.userHasRole(rolename);
    },
    changeOffice() {
      if (this.datasource.user.office_id == "") return;

      let office_id = this.datasource.user.office_id.value;
      let office_departments = this.datasource.stack_departments.filter(
        (q) => q.office_id == office_id
      );
      this.datasource.departments = [];
      this.datasource.user.department_id = "";
      office_departments.forEach((q) => {
        q.value = q.definition_id;
        q.label = q.name;
        this.datasource.departments.push(q);
      });

      let office_users = this.datasource.stack_users.filter(
        (q) => q.office_id == office_id
      );
      this.datasource.users = [];
      office_users.forEach((q) => {
        q.value = q.user_id;
        q.label = q.user_namesurname;
        this.datasource.users.push(q);
      });
    },
    save() {
      try {
        let u = this.datasource.user;
        if (u.namesurname.length <= 3) {
          this.toast("danger", "Bilgi", "Kullanıcının ad ve soyadını yazın.");
          return;
        }
        if (u.email_primary.length <= 3) {
          this.toast("danger", "Bilgi", "Kullanıcının email adresini yazın.");
          return;
        }
        if (u.gsm_primary.length <= 3) {
          this.toast(
            "danger",
            "Bilgi",
            "Kullanıcının öncelikli gsm numarasını yazın."
          );
          return;
        }
        if (u.password.length >= 1 && u.password.length <= 5) {
          this.toast(
            "danger",
            "Bilgi",
            "Kullanıcının parolasını değiştirmek için en az 6 haneden oluşan güvenli bir parola yazın."
          );
          return;
        }
        if (
          u.office_id == null ||
          u.office_id == "" ||
          u.office_id.value == ""
        ) {
          this.toast(
            "danger",
            "Bilgi",
            "Kullanıcının görev alacağı şubesini seçin."
          );
          return;
        }
        if (
          u.department_id == null ||
          u.department_id == "" ||
          u.department_id.value == ""
        ) {
          this.toast("danger", "Bilgi", "Kullanıcının departmanını seçin.");
          return;
        }
        if (u.parent_user_id == null || u.parent_user_id.value == "") {
          this.toast("danger", "Bilgi", "Kullanıcının yöneticisini seçin.");
          return;
        }
        if (u.title_name == null || u.title_name.value == "") {
          this.toast("danger", "Bilgi", "Kullanıcının ünvanını seçin.");
          return;
        }

        let postData = {};
        postData.user_uniq = this.datasource.user_stack.user.user_uniq;

        Object.entries(u).forEach((q) => {
          let key = q[0];
          let val = _.isObject(q[1]) ? q[1].value : q[1];
          postData[key] = val;
        });

        postData.roles = this.datasource.roles
          .filter((q) => q.is_checked == true)
          .map((q) => q.role);

        api.action("userUpdate", postData).then((q) => {
          if (!q.success) {
            this.toast("warning", "Bilgi", q.message);
            return;
          }
          this.toast("success", "Kayıt Güncellendi", q.message);
        });
      } catch (err) {
        console.error(err);
        utils.setError(err, "user_update", {});
      }
    },
    userlogout() {
      let data = { user_uniq: this.datasource.user_stack.user.user_uniq };
      api.action("userLogout", data).then((q) => {
        if (q.success) {
          this.toast("success", "Bilgi", q.message);
        } else {
          this.toast("warning", "Bilgi", q.message);
        }
      });
    },
    confirmDelete() {
      this.$swal({
        title: "Kullanıcı Sil",
        text: "Kullanıcıyı silmek istediğinizden emin misiniz?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Onayla",
        cancelButtonText: "İptal",
        customClass: { ...utils.defaultSweetStyle },
        buttonsStyling: false,
      }).then((q) => {
        if (q.isConfirmed) {
          api
            .action("userDelete", {
              user_uniq: this.datasource.user_stack.user.user_uniq,
            })
            .then((q) => {
              if (q.success) {
                this.toast("success", "Bilgi", q.message);

                this.$bvModal.hide("modaldefinition");
                this.$router.push({ name: "user-list" });
              } else {
                this.toast("warning", "Bilgi", q.message);
              }
            });
        }
      });
    },
    toast(type = "warning", title = "", text = "") {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: title,
          icon: "AlertCircleIcon",
          variant: type,
          text: text,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>